
































import { Component, Vue } from 'vue-property-decorator'
import { BSpinner } from 'bootstrap-vue'
import { customer } from '@/store/customer'

@Component({ components: { BSpinner } })
export default class WaitingForTerminal extends Vue {
  get isBonusesPayment(): boolean {
    return customer.spendScores
  }
}
